exports.components = {
  "component---src-components-layout-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-404-mdx": () => import("./../../../src/components/Layout/index.tsx?__contentFilePath=/home/runner/work/kotlinfoundation.github.io/kotlinfoundation.github.io/src/pages/404.mdx" /* webpackChunkName: "component---src-components-layout-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-404-mdx" */),
  "component---src-components-layout-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-faq-mdx": () => import("./../../../src/components/Layout/index.tsx?__contentFilePath=/home/runner/work/kotlinfoundation.github.io/kotlinfoundation.github.io/src/pages/faq.mdx" /* webpackChunkName: "component---src-components-layout-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-faq-mdx" */),
  "component---src-components-layout-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-grants-mdx": () => import("./../../../src/components/Layout/index.tsx?__contentFilePath=/home/runner/work/kotlinfoundation.github.io/kotlinfoundation.github.io/src/pages/grants.mdx" /* webpackChunkName: "component---src-components-layout-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-grants-mdx" */),
  "component---src-components-layout-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-guidelines-mdx": () => import("./../../../src/components/Layout/index.tsx?__contentFilePath=/home/runner/work/kotlinfoundation.github.io/kotlinfoundation.github.io/src/pages/guidelines.mdx" /* webpackChunkName: "component---src-components-layout-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-guidelines-mdx" */),
  "component---src-components-layout-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-index-mdx": () => import("./../../../src/components/Layout/index.tsx?__contentFilePath=/home/runner/work/kotlinfoundation.github.io/kotlinfoundation.github.io/src/pages/index.mdx" /* webpackChunkName: "component---src-components-layout-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-index-mdx" */),
  "component---src-components-layout-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-join-mdx": () => import("./../../../src/components/Layout/index.tsx?__contentFilePath=/home/runner/work/kotlinfoundation.github.io/kotlinfoundation.github.io/src/pages/join.mdx" /* webpackChunkName: "component---src-components-layout-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-join-mdx" */),
  "component---src-components-layout-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-language-committee-guidelines-mdx": () => import("./../../../src/components/Layout/index.tsx?__contentFilePath=/home/runner/work/kotlinfoundation.github.io/kotlinfoundation.github.io/src/pages/language-committee-guidelines.mdx" /* webpackChunkName: "component---src-components-layout-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-language-committee-guidelines-mdx" */),
  "component---src-components-layout-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-structure-mdx": () => import("./../../../src/components/Layout/index.tsx?__contentFilePath=/home/runner/work/kotlinfoundation.github.io/kotlinfoundation.github.io/src/pages/structure.mdx" /* webpackChunkName: "component---src-components-layout-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-structure-mdx" */),
  "component---src-components-layout-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-submitting-incompatible-changes-mdx": () => import("./../../../src/components/Layout/index.tsx?__contentFilePath=/home/runner/work/kotlinfoundation.github.io/kotlinfoundation.github.io/src/pages/submitting-incompatible-changes.mdx" /* webpackChunkName: "component---src-components-layout-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-submitting-incompatible-changes-mdx" */),
  "component---src-components-post-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-news-announcing-membership-program-index-md": () => import("./../../../src/components/Post/index.tsx?__contentFilePath=/home/runner/work/kotlinfoundation.github.io/kotlinfoundation.github.io/src/pages/news/announcing-membership-program/index.md" /* webpackChunkName: "component---src-components-post-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-news-announcing-membership-program-index-md" */),
  "component---src-components-post-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-news-annual-report-2023-index-md": () => import("./../../../src/components/Post/index.tsx?__contentFilePath=/home/runner/work/kotlinfoundation.github.io/kotlinfoundation.github.io/src/pages/news/annual-report-2023/index.md" /* webpackChunkName: "component---src-components-post-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-news-annual-report-2023-index-md" */),
  "component---src-components-post-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-news-first-silver-members-index-md": () => import("./../../../src/components/Post/index.tsx?__contentFilePath=/home/runner/work/kotlinfoundation.github.io/kotlinfoundation.github.io/src/pages/news/first-silver-members/index.md" /* webpackChunkName: "component---src-components-post-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-news-first-silver-members-index-md" */),
  "component---src-components-post-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-news-foundation-changes-oct-23-index-md": () => import("./../../../src/components/Post/index.tsx?__contentFilePath=/home/runner/work/kotlinfoundation.github.io/kotlinfoundation.github.io/src/pages/news/foundation-changes-oct-23/index.md" /* webpackChunkName: "component---src-components-post-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-news-foundation-changes-oct-23-index-md" */),
  "component---src-components-post-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-news-grants-program-continues-in-2024-index-md": () => import("./../../../src/components/Post/index.tsx?__contentFilePath=/home/runner/work/kotlinfoundation.github.io/kotlinfoundation.github.io/src/pages/news/grants-program-continues-in-2024/index.md" /* webpackChunkName: "component---src-components-post-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-news-grants-program-continues-in-2024-index-md" */),
  "component---src-components-post-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-news-grants-program-goes-live-index-md": () => import("./../../../src/components/Post/index.tsx?__contentFilePath=/home/runner/work/kotlinfoundation.github.io/kotlinfoundation.github.io/src/pages/news/grants-program-goes-live/index.md" /* webpackChunkName: "component---src-components-post-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-news-grants-program-goes-live-index-md" */),
  "component---src-components-post-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-news-grants-program-winners-23-index-md": () => import("./../../../src/components/Post/index.tsx?__contentFilePath=/home/runner/work/kotlinfoundation.github.io/kotlinfoundation.github.io/src/pages/news/grants-program-winners-23/index.md" /* webpackChunkName: "component---src-components-post-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-news-grants-program-winners-23-index-md" */),
  "component---src-components-post-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-news-grants-program-winners-24-index-md": () => import("./../../../src/components/Post/index.tsx?__contentFilePath=/home/runner/work/kotlinfoundation.github.io/kotlinfoundation.github.io/src/pages/news/grants-program-winners-24/index.md" /* webpackChunkName: "component---src-components-post-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-news-grants-program-winners-24-index-md" */),
  "component---src-components-post-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-news-gsoc-2023-eclipse-gradle-kotlin-index-md": () => import("./../../../src/components/Post/index.tsx?__contentFilePath=/home/runner/work/kotlinfoundation.github.io/kotlinfoundation.github.io/src/pages/news/gsoc-2023-eclipse-gradle-kotlin/index.md" /* webpackChunkName: "component---src-components-post-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-news-gsoc-2023-eclipse-gradle-kotlin-index-md" */),
  "component---src-components-post-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-news-gsoc-2023-kflogger-index-md": () => import("./../../../src/components/Post/index.tsx?__contentFilePath=/home/runner/work/kotlinfoundation.github.io/kotlinfoundation.github.io/src/pages/news/gsoc-2023-kflogger/index.md" /* webpackChunkName: "component---src-components-post-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-news-gsoc-2023-kflogger-index-md" */),
  "component---src-components-post-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-news-gsoc-2023-kotlinx-benchmark-index-md": () => import("./../../../src/components/Post/index.tsx?__contentFilePath=/home/runner/work/kotlinfoundation.github.io/kotlinfoundation.github.io/src/pages/news/gsoc-2023-kotlinx-benchmark/index.md" /* webpackChunkName: "component---src-components-post-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-news-gsoc-2023-kotlinx-benchmark-index-md" */),
  "component---src-components-post-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-news-gsoc-2023-parallel-stacks-index-md": () => import("./../../../src/components/Post/index.tsx?__contentFilePath=/home/runner/work/kotlinfoundation.github.io/kotlinfoundation.github.io/src/pages/news/gsoc-2023-parallel-stacks/index.md" /* webpackChunkName: "component---src-components-post-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-news-gsoc-2023-parallel-stacks-index-md" */),
  "component---src-components-post-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-news-kotlin-multiplatform-contest-results-index-md": () => import("./../../../src/components/Post/index.tsx?__contentFilePath=/home/runner/work/kotlinfoundation.github.io/kotlinfoundation.github.io/src/pages/news/kotlin-multiplatform-contest-results/index.md" /* webpackChunkName: "component---src-components-post-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-news-kotlin-multiplatform-contest-results-index-md" */),
  "component---src-components-post-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-news-kotlin-multiplatform-contest-winners-spotlight-index-md": () => import("./../../../src/components/Post/index.tsx?__contentFilePath=/home/runner/work/kotlinfoundation.github.io/kotlinfoundation.github.io/src/pages/news/kotlin-multiplatform-contest-winners-spotlight/index.md" /* webpackChunkName: "component---src-components-post-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-news-kotlin-multiplatform-contest-winners-spotlight-index-md" */),
  "component---src-components-post-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-news-kotzilla-joins-kotlin-foundation-silver-member-index-md": () => import("./../../../src/components/Post/index.tsx?__contentFilePath=/home/runner/work/kotlinfoundation.github.io/kotlinfoundation.github.io/src/pages/news/kotzilla-joins-kotlin-foundation-silver-member/index.md" /* webpackChunkName: "component---src-components-post-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-news-kotzilla-joins-kotlin-foundation-silver-member-index-md" */),
  "component---src-components-post-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-news-new-ecosystem-committee-index-md": () => import("./../../../src/components/Post/index.tsx?__contentFilePath=/home/runner/work/kotlinfoundation.github.io/kotlinfoundation.github.io/src/pages/news/new-ecosystem-committee/index.md" /* webpackChunkName: "component---src-components-post-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-news-new-ecosystem-committee-index-md" */),
  "component---src-components-post-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-news-new-president-and-new-board-member-appointed-index-md": () => import("./../../../src/components/Post/index.tsx?__contentFilePath=/home/runner/work/kotlinfoundation.github.io/kotlinfoundation.github.io/src/pages/news/new-president-and-new-board-member-appointed/index.md" /* webpackChunkName: "component---src-components-post-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-news-new-president-and-new-board-member-appointed-index-md" */),
  "component---src-components-post-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-news-uber-becomes-kotlin-foundation-silver-member-index-md": () => import("./../../../src/components/Post/index.tsx?__contentFilePath=/home/runner/work/kotlinfoundation.github.io/kotlinfoundation.github.io/src/pages/news/uber-becomes-kotlin-foundation-silver-member/index.md" /* webpackChunkName: "component---src-components-post-index-tsx-content-file-path-home-runner-work-kotlinfoundation-github-io-kotlinfoundation-github-io-src-pages-news-uber-becomes-kotlin-foundation-silver-member-index-md" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */)
}

